<template>
  <div class="themeSwitcher-container">
    <v-switch
      v-bind="$attrs"
      v-model="theme.global.name.value"
      hide-details
      inset
      flat
      true-value="dark"
      false-value="light"
      density="comfortable"
      class="d-flex ma-auto"
    />

    <div class="themeSwitch__slider__container">
      <v-icon icon="mdi-weather-sunny" class="" />
      <v-icon icon="mdi-weather-night" class="" />
      <div class="themeSwitch__slider" />
      <div class="themeSwitch__bg" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useTheme } from "vuetify";
import { useLocalStorage } from "@vueuse/core";

const theme = useTheme();

const preferredTheme = useLocalStorage("preferredTheme", "light");
theme.global.name.value = preferredTheme.value;

watch(() => theme.global.name.value, () => {
  preferredTheme.value = theme.global.name.value;
});

</script>
