import type {components} from "~/types/api-spec";
import {useAuthStore} from "#build/imports";

type Dispatcher = components["schemas"]["SimpleDispatcher"];

export default function useMenu() {
  const localePath = useLocalePath();
  const commonData = useCommonFormData();
  const authStore = useAuthStore();

  const dispatcher = ref<Dispatcher | null | undefined>(undefined);
  const {getCurrentUser} = useAuthStore();
  const currentUser = getCurrentUser();
  const isStaff = currentUser.is_staff || ref<boolean>(false);

  // Get tabs for watcher based on "tracked"
  const watcherSelfTab = {title: "Vlastní", to: localePath({name: "dispecink-hlidac-vlastni"})};
  const watcherLoadingTab = {title: "Opožděné", to: localePath({name: "dispecink-hlidac-opozdene"}), loading: true};

  const watcherMenu: MenuItem = reactive({
    title: "Hlídač",
    to: localePath({name: "dispecink-hlidac"}),
    tabs: [
      watcherSelfTab,
      watcherLoadingTab
    ],
    tabsLoader: updateWatcherTabs
  });

  function updateWatcherTabs() {
    watch(() => authStore.isLoggedIn, () => {
      if (authStore.loading) {
        return;
      }
      if (!authStore.sessionData?.id || !authStore.isLoggedIn) {
        watcherMenu.tabs = [watcherSelfTab];
        return;
      }
      if (dispatcher.value && dispatcher.value.user === authStore.sessionData.id) {
        return;
      }

      dispatcher.value = authStore.sessionData.dispatcher;
      if (!dispatcher.value || !dispatcher.value.watcher) {
        watcherMenu.tabs = [watcherSelfTab];
        return;
      }

      commonData.dispatchers.getAsync({
        params: {tracked: true},
        opts: {priority: "high"}
      }, {cache: "persistNavigation"}).then((tracked) => {
        watcherMenu.tabs = [
          watcherSelfTab,
          {title: "Opožděné", to: localePath({name: "dispecink-hlidac-opozdene"}), loading: false},
          ...tracked!.map(dispatcher => ({
            title: dispatcher.user.display_name,
            to: localePath({name: "dispecink-hlidac-id", params: {id: dispatcher.user.id}})
          }))
        ];
      });
    }, {immediate: true});
  }

  function insertMessagesCount (menu: MenuItem, numberOfMessages: number) {
    for (const child of menu?.children || []) {
      if (child.title.startsWith("Vzkazy")) {
        if (numberOfMessages === 0) {
          child.title = "Vzkazy";
          child.cssClass = "";
        } else {
          child.title = `Vzkazy <span class="message-count">${numberOfMessages > 99 ? "+99" : numberOfMessages}</span>`;
        }
      }
    }
  }

  const staffMenu: MenuItem[] = [
    {
      title: "Nemo",
      to: localePath({name: "dispecink-nemo"}),
      children: [
        {title: "Údaje", to: localePath({name: "dispecink-nemo-udaje"})},
        {title: "Soubory", to: localePath({name: "dispecink-nemo-soubory"})}
      ]
    },
    {
      title: "Sestavy",
      to: localePath({name: "dispecink-sestavy"}),
      cssClass: "menu-item--left-separator",
      children: [
        {
          title: "Sledovaný materiál",
          to: localePath({name: "dispecink-sestavy-sledovany-material"}),
          tabs: [
            {title: "S množstvím", to: localePath({name: "dispecink-sestavy-sledovany-material-s-mnozstvim"})},
            {title: "S plánem", to: localePath({name: "dispecink-sestavy-sledovany-material-s-planem"})},
            {title: "Bez plánu", to: localePath({name: "dispecink-sestavy-sledovany-material-bez-planu"})},
            {title: "Všechen", to: localePath({name: "dispecink-sestavy-sledovany-material-vsechen"})}
          ]
        },
        {
          title: "Materiál údálosti",
          to: localePath({name: "dispecink-sestavy-material-udalosti"}),
          tabs: [
            {
              title: "Všechny",
              to: localePath({name: "dispecink-sestavy-material-udalosti-vsechny"})
            },
            {
              title: "Se sledovaným materiálem",
              to: localePath({name: "dispecink-sestavy-material-udalosti-se-sledovanym-materialem"})
            },
            {
              title: "Z nabídek",
              to: localePath({name: "dispecink-sestavy-material-udalosti-z-nabidek"})
            },
            {
              title: "S vykazem",
              to: localePath({name: "dispecink-sestavy-material-udalosti-s-vykazem"})
            }
          ]
        }
      ]
    }
  ];

  const controlRoomMenu: MenuItem[] = [
    watcherMenu,
    {
      title: "Lokality",
      to: localePath({name: "dispecink-lokality"}),
      tabs: [
        {title: "Aktivní", to: localePath({name: "dispecink-lokality-aktivni"})},
        {title: "Zrušené", to: localePath({name: "dispecink-lokality-zrusene"})},
        {title: "Alarmy", to: localePath({name: "dispecink-lokality-alarmy"})},
        {title: "Náročnosti", to: localePath({name: "dispecink-lokality-narocnosti"})}
      ]
    },
    {
      title: "Události",
      to: localePath({name: "dispecink-udalosti"}),
      tabs: [
        {title: "Aktivní", to: localePath({name: "dispecink-udalosti-aktivni"})},
        {title: "Zpracovat", to: localePath({name: "dispecink-udalosti-zpracovat"})},
        {title: "Fakturovat", to: localePath({name: "dispecink-udalosti-fakturovat"})},
        {title: "Uzavřené", to: localePath({name: "dispecink-udalosti-uzavrene"})},
        {title: "Zrušené", to: localePath({name: "dispecink-udalosti-zrusene"})},
        {title: "Vše", to: localePath({name: "dispecink-udalosti-vse"})}
      ]
    },
    {
      title: "Partneři",
      to: localePath({name: "dispecink-partneri"}),
      tabs: [
        {title: "S lokalitami", to: localePath({name: "dispecink-partneri-s-lokalitami"})},
        {title: "Všichni", to: localePath({name: "dispecink-partneri-vsichni"})}
      ]
    },
    {
      title: "Plány",
      to: localePath({name: "dispecink-plany"}),
      tabs: [
        {title: "Aktivní", to: localePath({name: "dispecink-plany-aktivni"})},
        {title: "Všechny", to: localePath({name: "dispecink-plany-vsechny"})},
        {title: "K plánu", to: localePath({name: "dispecink-plany-k-planu"})},
        {title: "K revizi úniku", to: localePath({name: "dispecink-plany-k-revizi-uniku"})},
        {title: "K výměně ventilu", to: localePath({name: "dispecink-plany-k-revizi-ventilu"})},
        {
          title: "K revizi protipožárních klapek",
          to: localePath({name: "dispecink-plany-k-revizi-protipozarnich-klapek"})
        },
        {title: "K revizi TNS (provozní)", to: localePath({name: "dispecink-plany-k-revizi-tns-provozni"})},
        {title: "K revizi TNS (vnitřní)", to: localePath({name: "dispecink-plany-k-revizi-tns-vnitrni"})},
        {
          title: "K revizi TNS (tlaková zkouška)",
          to: localePath({name: "dispecink-plany-k-revizi-tns-tlakova-zkouska"})
        }
      ]
    },
    {
      title: "Požadavky",
      to: localePath({name: "dispecink-pozadavky"}),
      tabs: [
        {title: "Platné", to: localePath({name: "dispecink-pozadavky-platne"})},
        {title: "Ukončené", to: localePath({name: "dispecink-pozadavky-ukoncene"})},
        {title: "Budoucí", to: localePath({name: "dispecink-pozadavky-budouci"})},
        {title: "Všechny", to: localePath({name: "dispecink-pozadavky-vsechny"})}
      ]
    },
    {
      title: "Vzkazy",
      to: localePath({name: "dispecink-vzkazy"}),
      tabs: [
        {title: "Pro mě", to: localePath({name: "dispecink-vzkazy-pro-me"})},
        {title: "Vlastní (k ukončení)", to: localePath({name: "dispecink-vzkazy-vlastni"})},
        {title: "Připravené", to: localePath({name: "dispecink-vzkazy-pripravene"})},
        {title: "Zobrazené", to: localePath({name: "dispecink-vzkazy-zobrazene"})},
        {title: "K ukončení", to: localePath({name: "dispecink-vzkazy-k-ukonceni"})},
        {title: "Ukončené", to: localePath({name: "dispecink-vzkazy-ukoncene"})},
        {title: "Všechny", to: localePath({name: "dispecink-vzkazy-vsechny"})}
      ]
    },
    {
      title: "Úkoly",
      to: localePath({name: "dispecink-ukoly"}),
      tabs: [
        {title: "Připravené", to: localePath({name: "dispecink-ukoly-pripravene"})},
        {title: "Alarmy", to: localePath({name: "dispecink-ukoly-alarmy"})},
        {title: "Všechny", to: localePath({name: "dispecink-ukoly-vsechny"})}
      ]
    },
    {
      title: "Výkazy",
      to: localePath({name: "dispecink-vykazy"}),
      tabs: [
        {title: "Všechny", to: localePath({name: "dispecink-vykazy-vsechny"})},
        {title: "K fakturaci", to: localePath({name: "dispecink-vykazy-k-fakturaci"})}
      ]
    },
    {title: "Hlášení", to: localePath({name: "dispecink-hlaseni"})},
    {
      title: "Nabídky",
      to: localePath({name: "dispecink-nabidky"}),
      tabs: [
        {title: "Rozpracované", to: localePath({name: "dispecink-nabidky-rozpracovane"})},
        {title: "Připravené", to: localePath({name: "dispecink-nabidky-pripravene"})},
        {title: "Odeslané", to: localePath({name: "dispecink-nabidky-odeslane"})},
        {title: "K upomínce", to: localePath({name: "dispecink-nabidky-k-upomince"})},
        {title: "Upomínané", to: localePath({name: "dispecink-nabidky-upominane"})},
        {title: "Schválené", to: localePath({name: "dispecink-nabidky-schvalene"})},
        {title: "Realizované", to: localePath({name: "dispecink-nabidky-realizovane"})},
        {title: "Nepřijaté", to: localePath({name: "dispecink-nabidky-neprijate"})},
        {title: "Všechny nabídky", to: localePath({name: "dispecink-nabidky-vsechny-nabidky"})},
        {title: "Všechny položky", to: localePath({name: "dispecink-nabidky-vsechny-polozky"})},
        {title: "Vzory", to: localePath({name: "dispecink-nabidky-vzory"})}
      ]
    },
    {
      title: "Faktury",
      to: localePath({name: "dispecink-faktury"}),
      tabs: [
        {title: "Otevřené", to: localePath({name: "dispecink-faktury-otevrene"})},
        {title: "Uzavřené", to: localePath({name: "dispecink-faktury-uzavrene"})},
        {title: "K přenosu", to: localePath({name: "dispecink-faktury-k-prenosu"})},
        {title: "Přenesené", to: localePath({name: "dispecink-faktury-prenesene"})},
        {title: "Opravované", to: localePath({name: "dispecink-faktury-opravovane"})},
        {title: "Opravené k přenosu", to: localePath({name: "dispecink-faktury-opravene-k-prenosu"})},
        {title: "Převzaté z Qi", to: localePath({name: "dispecink-faktury-prevzate-z-qi"})},
        {title: "Zrušené", to: localePath({name: "dispecink-faktury-zrusene"})},
        {title: "Všechny", to: localePath({name: "dispecink-faktury-vsechny"})}
      ]
    },
    {
      title: "Ceník",
      to: localePath({name: "dispecink-cenik"}),
      tabs: [
        {title: "Aktivní", to: localePath({name: "dispecink-cenik-aktivni"})},
        {title: "Vyřazené", to: localePath({name: "dispecink-cenik-vyrazene"})}
      ]
    },
    {title: "Obchodní případy", to: localePath({name: "dispecink-obchodni-pripady"})},
    {
      title: "Předávácí protokoly",
      to: localePath({name: "dispecink-predavaci-protokoly"}),
      tabs: [
        {title: "Rozpracované", to: localePath({name: "dispecink-predavaci-protokoly-rozpracovane"})},
        {title: "Odeslané", to: localePath({name: "dispecink-predavaci-protokoly-odeslane"})},
        {title: "Všechny", to: localePath({name: "dispecink-predavaci-protokoly-vsechny"})}
      ]
    },
    {
      title: "Náhradní řešení",
      to: localePath({name: "dispecink-nahradni-reseni"}),
      tabs: [
        {title: "Instalovaná", to: localePath({name: "dispecink-nahradni-reseni-instalovane"})},
        {title: "Odstraněná", to: localePath({name: "dispecink-nahradni-reseni-odstranene"})}
      ]
    },
    {title: "Adresy", to: localePath({name: "dispecink-adresy"})},
    {
      title: "Střediska",
      to: localePath({name: "dispecink-strediska"}),
      tabs: [
        {title: "Aktivní", to: localePath({name: "dispecink-strediska-aktivni"})},
        {title: "Neaktivní", to: localePath({name: "dispecink-strediska-neaktivni"})}
      ]
    },
    {
      title: "Dokumentace",
      to: localePath({name: "dispecink-dokumentace"}),
      tabs: [
        {title: "Vybraná", to: localePath({name: "dispecink-dokumentace-vybrana"})},
        {title: "Všechna", to: localePath({name: "dispecink-dokumentace-vsechna"})}
      ]
    },
    {title: "Vzory textů", to: localePath({name: "dispecink-vzory-textu"})},
    {
      title: "Typy dílů",
      to: localePath({name: "dispecink-typy-dilu"}),
      tabs: [
        {title: "Připravené", to: localePath({name: "dispecink-typy-dilu-pripravene"})},
        {title: "Vyřazené", to: localePath({name: "dispecink-typy-dilu-vyrazene"})}
      ]
    },
    {
      title: "Stavy skladů",
      to: localePath({name: "dispecink-stavy-skladu"}),
      tabs: [
        {title: "Skladové", to: localePath({name: "dispecink-stavy-skladu-skladove"})},
        {title: "Všechny", to: localePath({name: "dispecink-stavy-skladu-vsechny"})}
      ]
    },
    {title: "Poznámky", to: localePath({name: "dispecink-poznamky"})},
    {title: "Zástupy", to: localePath({name: "dispecink-zastupy"})}

  ];
  if (isStaff) {
    controlRoomMenu.push(...staffMenu);
  }

  const dutyMenu: MenuItem[] = [
    {title: "Události", to: localePath({name: "sluzba-udalosti"})},
    {title: "Dokumentace", to: localePath({name: "sluzba-dokumentace"})}
  ];

  const serviceMenu: MenuItem[] = [
    {title: "Přehled", to: localePath({name: "servis-prehled"})},
    {title: "Den", to: localePath({name: "servis-den"})},
    {title: "Týden", to: localePath({name: "servis-tyden"})},
    {title: "Měsíc", to: localePath({name: "servis-mesic"})},
    {
      title: "Akce",
      to: localePath({name: "servis-akce"}),
      tabs: [
        {
          title: "Aktivní",
          to: localePath({name: "servis-akce-aktivni"})
        },
        {
          title: "Neaktivní",
          to: localePath({name: "servis-akce-neaktivni"})
        },
        {
          title: "Rozplánované",
          to: localePath({name: "servis-akce-rozplanovane"})
        },
        {
          title: "Nenaplánované",
          to: localePath({name: "servis-akce-nenaplanovane"})
        },
        {
          title: "Provedené",
          to: localePath({name: "servis-akce-provedene"})
        },
        {
          title: "Zrušené",
          to: localePath({name: "servis-akce-zrusene"})
        },
        {
          title: "Všechny",
          to: localePath({name: "servis-akce-vsechny"})
        }
      ]
    },
    {
      title: "Výjezdy",
      to: localePath({name: "servis-vyjezdy"}),
      tabs: [
        {
          title: "Naplánované",
          to: localePath({name: "servis-vyjezdy-naplanovane"})
        },
        {
          title: "Rozplánované",
          to: localePath({name: "servis-vyjezdy-rozplanovane"})
        }
      ]
    },
    {title: "Technici", to: localePath({name: "servis-technici"})},
    {
      title: "Služby",
      to: localePath({name: "servis-sluzby"}),
      tabs: [
        {
          title: "Přehled",
          to: localePath({name: "servis-sluzby-prehled"})
        },
        {
          title: "Seznam",
          to: localePath({name: "servis-sluzby-seznam"})
        }
      ]
    },
    {
      title: "Absence",
      to: localePath({name: "servis-absence"}),
      children: [
        {
          title: "Techniků",
          to: localePath({name: "servis-absence-techniku"}),
          tabs: [
            {title: "Přehled", to: localePath({name: "servis-absence-techniku-prehled"})},
            {title: "Seznam", to: localePath({name: "servis-absence-techniku-seznam"})}
          ]
        },
        {
          title: "Vozidel",
          to: localePath({name: "servis-absence-vozidel"}),
          tabs: [
            {title: "Přehled", to: localePath({name: "servis-absence-vozidel-prehled"})},
            {title: "Seznam", to: localePath({name: "servis-absence-vozidel-seznam"})}
          ]
        }
      ]
    },
    {
      title: "Chladiva",
      to: localePath({name: "servis-chladiva"}),
      tabs: [
        {title: "Nová", to: localePath({name: "servis-chladiva-nova"})},
        {title: "Odsátá", to: localePath({name: "servis-chladiva-odsata"})},
        {title: "Stav", to: localePath({name: "servis-chladiva-stav"})}
      ]
    },
    {
      title: "Číselníky",
      to: localePath({name: "servis-ciselniky"}),
      children: [
        {title: "Střediska", to: localePath({name: "servis-ciselniky-strediska"})},
        {title: "Vozidla", to: localePath({name: "servis-ciselniky-vozidla"})},
        {title: "Technici", to: localePath({name: "servis-ciselniky-technici"})}
      ]
    },
    {
      title: "Vzkazy",
      to: localePath({name: "servis-vzkazy"}),
      tabs: [
        {title: "Vlastní", to: localePath({name: "servis-vzkazy-vlastni"})},
        {title: "Připravené", to: localePath({name: "servis-vzkazy-pripravene"})},
        {title: "Zobrazené", to: localePath({name: "servis-vzkazy-zobrazene"})},
        {title: "Hotové", to: localePath({name: "servis-vzkazy-hotove"})},
        {title: "Ukončené", to: localePath({name: "servis-vzkazy-ukoncene"})},
        {title: "Všechny", to: localePath({name: "servis-vzkazy-vsechny"})}
      ]
    },
    {title: "Historie", to: localePath({name: "servis-historie"})},
    {title: "Dokumentace", to: localePath({name: "servis-dokumentace"})},
    {
      title: "Nemo",
      to: localePath({name: "servis-nemo"}),
      children: [
        {title: "Údaje", to: localePath({name: "servis-nemo-udaje"})},
        {title: "Soubory", to: localePath({name: "servis-nemo-soubory"})}
      ]
    },
    {
      title: "Test",
      to: localePath({name: "servis-test"})
    }
  ];

  const fullMenu: MenuItem[] = [
    {title: "Dispečink", to: localePath({name: "dispecink"}), children: controlRoomMenu},
    {title: "Servis", to: localePath({name: "servis"}), children: serviceMenu},
    {title: "Obchod", to: localePath({name: "obchod"}), children: []},
    {title: "Služba", to: localePath({name: "sluzba"}), children: dutyMenu}
  ];

  return {
    insertMessagesCount,
    controlRoomMenu,
    fullMenu
  };
}
